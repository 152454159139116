/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  & ::-webkit-scrollbar {
    width: 4px;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #696770;
    border-radius: 4px;
    &:hover {
      background: #cfcdd8;
    }
  }
}

:root {
  --e-active-color: #e1ff67;
  --e-main__bg: #0e0e14;
  --e-text__color: #fff;
  --e-category-list__bg: #1f1d29;
  --e-body-option__bg: #191720;
  --e-icon__color: #eeeeee;
  --e-icon__active-color: var(--e-active-color);
  --e-asset__bg: rgba(58, 50, 73, 0.7);
  --e-asset__active-bg: var(--e-active-color);
  --e-body__active-bg: var(--e-active-color);
  --e-home-btn__bg: rgba(0, 0, 0, 0.3);
  --e-home-btn__color: #9991bf;

  --e-body-option-image__bg: #f4f3fb;
  --e-slider__active-bg: var(--e-active-color);

  --e-save-btn__bg: var(--e-active-color);
  --e-save-btn__color: #0c0c0f;

  --e-color-input__bg: #fff;
  --e-save-btn__bg: var(--e-active-color);
  --e-save-btn__color: #000;

  // Scan
  --s-main__bg: #1a1b22;
  --s-active-color: var(--e-active-color);
  --s-btn__bg: var(--s-active-color);
}
